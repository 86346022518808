import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Trading/Form/CancelButton.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Number/Index.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Common/Table/Row.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "flex items-center justify-between text-xs leading-5" };
const _hoisted_2 = { class: "flex items-center gap-1" };
const _hoisted_3 = {
  key: 0,
  class: "w-4 h-4"
};
const _hoisted_4 = { class: "text-gray-200 font-semibold" };
const _hoisted_5 = {
  key: 1,
  class: "font-mono"
};
const _hoisted_6 = {
  key: 0,
  class: "mt-0.5 text-gray-500 uppercase tracking-widest text-2xs"
};
const _hoisted_7 = { class: "text-gray-500 uppercase tracking-widest text-3xs" };
const _hoisted_8 = { class: "text-right" };
const _hoisted_9 = {
  key: 0,
  class: "text-white text-xs"
};
const _hoisted_10 = { class: "text-gray-500 uppercase tracking-widest text-3xs" };
const _hoisted_11 = { class: "flex items-center gap-1 justify-end" };
const _hoisted_12 = /* @__PURE__ */ _createElementVNode("span", null, "/", -1);
const _hoisted_13 = { class: "text-gray-500 uppercase tracking-widest text-3xs" };
const _hoisted_14 = { class: "text-right" };
const _hoisted_15 = { class: "text-2xs text-gray-500" };
import { Status } from "@injectivelabs/utils";
import { getMarketRoute } from "@/app/utils/market";
export default /* @__PURE__ */ _defineComponent({
  __name: "Mobile",
  props: {
    trigger: {
      required: true,
      type: Object
    }
  },
  setup(__props) {
    const props = __props;
    const derivativeStore = useDerivativeStore();
    const { success } = useNotifications();
    const { $onError } = useNuxtApp();
    const { t } = useLang();
    const status = reactive(new Status());
    const {
      isBuy,
      total,
      price,
      market,
      quantity,
      leverage,
      isReduceOnly,
      filledQuantity,
      isCancelable,
      isMarketOrder,
      priceDecimals,
      quantityDecimals
    } = useTrigger(computed(() => props.trigger));
    function onCancelOrder() {
      status.setLoading();
      derivativeStore.cancelOrder(props.trigger).then(() => {
        success({ title: t("trade.order_success_canceling") });
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    const marketRoute = computed(() => {
      if (!market.value) {
        return void 0;
      }
      return getMarketRoute(market.value);
    });
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_PartialsTradingFormCancelButton = __nuxt_component_1;
      const _component_NuxtLink = __nuxt_component_2;
      const _component_AppNumber = __nuxt_component_3;
      const _component_CommonTableRow = __nuxt_component_4;
      return _unref(market) ? (_openBlock(), _createBlock(_component_CommonTableRow, {
        key: 0,
        dense: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NuxtLink, {
            class: "pb-1 col-span-2",
            to: _unref(marketRoute)
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("span", {
                    class: _normalizeClass({
                      "text-green-500": _unref(isBuy),
                      "text-red-500": !_unref(isBuy)
                    })
                  }, _toDisplayString(_unref(isBuy) ? _ctx.$t("trade.buy") : _ctx.$t("trade.sell")), 3),
                  _unref(market).baseToken ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_CommonTokenIcon, {
                      token: _unref(market).baseToken,
                      sm: ""
                    }, null, 8, ["token"])
                  ])) : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(market).ticker), 1),
                  _unref(leverage).gte(0) ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(leverage).toFormat(2)) + "x ", 1)) : _createCommentVNode("", true)
                ]),
                _unref(isCancelable) ? (_openBlock(), _createBlock(_component_PartialsTradingFormCancelButton, {
                  key: 0,
                  status: _unref(status),
                  sm: "",
                  onClick: onCancelOrder
                }, null, 8, ["status"])) : _createCommentVNode("", true)
              ]),
              _unref(isReduceOnly) ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t("trade.reduce_only")), 1)) : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["to"]),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("trade.price")), 1),
          _createElementVNode("div", _hoisted_8, [
            _unref(isMarketOrder) ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t("trade.market")), 1)) : (_openBlock(), _createBlock(_component_AppNumber, {
              key: 1,
              decimals: _unref(priceDecimals),
              number: _unref(price)
            }, null, 8, ["decimals", "number"]))
          ]),
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("trade.filled")) + " / " + _toDisplayString(_ctx.$t("trade.amount")), 1),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_AppNumber, {
              decimals: _unref(quantityDecimals),
              number: _unref(filledQuantity)
            }, null, 8, ["decimals", "number"]),
            _hoisted_12,
            _createVNode(_component_AppNumber, {
              decimals: _unref(quantityDecimals),
              number: _unref(quantity)
            }, null, 8, ["decimals", "number"])
          ]),
          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("trade.total")), 1),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_AppNumber, {
              decimals: _unref(priceDecimals),
              number: _unref(total)
            }, {
              addon: _withCtx(() => [
                _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(market).quoteToken.symbol), 1)
              ]),
              _: 1
            }, 8, ["decimals", "number"])
          ])
        ]),
        _: 1
      })) : _createCommentVNode("", true);
    };
  }
});
